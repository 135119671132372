<template>
  <div>
    <!-- user has no subscription -->
    <div v-if="!userSubscription" class="plus-banner">
      <div class="banner-content">
        <div class="information-side">
          <div class="banner-title">
            <img
              class="spartan-logo"
              src="@/assets/spartan_logo.svg"
              alt="helm"
            />
            <img
              class="spartan-text-logo"
              src="@/assets/Wordmark.svg"
              alt="spartan"
            />
            <div class="plus-icon">
              <div class="plus-line"></div>
              <div class="plus-line rotate-line"></div>
            </div>
          </div>
          <div class="banner-description">
            <div class="description-line">
              <i class="material-icons">done</i>
              <span>{{ $t("s_plus_banner_text_line_1") }}</span>
            </div>
            <div class="description-line">
              <i class="material-icons">done</i>
              <span>{{ $t("s_plus_banner_text_line_2") }}</span>
            </div>
            <div class="description-line">
              <i class="material-icons">done</i>
              <span>{{ $t("s_plus_banner_text_line_3") }}</span>
            </div>
          </div>
        </div>
        <div class="banner-buttons">
          <div class="close-button">
            <i
              v-if="canClose"
              @click="closeBanner()"
              class="material-icons cursor-pointer"
              >close</i
            >
          </div>
          <button
            class="mem-btn btn-primary"
            type="button"
            @click="
              () => {
                $router.push('/subscription');
              }
            "
          >
            <span class="mem-show-desktop">{{ $t("upgrade") }}</span>
            <span class="mem-show-mobile">{{ $t("go") }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- user has subscription with status past_due or unpaid -->
    <div v-if="userSubscription && feedView" class="subscription-notification">
      <div class="close-button" v-if="canClose" @click="closeBanner">
        <span class="material-icons">close</span>
      </div>
      <div class="notification-header">
        <img
          class="spartan-logo"
          src="../../../assets/spartan_logo.svg"
          alt="helm"
        />
        <img
          class="spartan-text-logo"
          src="../../../assets/Wordmark.svg"
          alt="spartan"
        />
        <div class="plus-icon">
          <div class="plus-line"></div>
          <div class="plus-line rotate-line"></div>
        </div>
      </div>
      <div class="notification-body">
        <div>
          <div>{{ $t("unpaid_subscription_line_1") }}</div>
          <div>{{ $t("unpaid_subscription_line_2") }}</div>
        </div>
        <mem-button
          :btn-type="'secondary-dark'"
          @click="openCustomerPortal"
          :loading="isLoading"
          >{{ $t("update") }}</mem-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    isLoading: false,
  }),
  props: {
    canClose: {
      type: Boolean,
      default: false,
    },
    feedView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userSubscription"]),
  },
  methods: {
    ...mapActions(["closeBanner", "getCustomerPortalLink"]),
    async openCustomerPortal() {
      if (this.isLoading) return;

      this.isLoading = true;
      let link = await this.getCustomerPortalLink();
      if (link.url) window.location = link.url;
      // this.isLoading = false;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.spartan-logo {
  height: 27px;
  width: 27px;
}
.spartan-text-logo {
  height: 26px;
  width: 106px;

  margin: 0 4px 0 2px;
}
.plus-icon {
  position: relative;
  .plus-line {
    height: 4px;
    width: 18px;
    background: #ffffff;
  }
  .rotate-line {
    position: absolute;
    top: 0;
    transform: rotate(90deg);
  }
}
.subscription-notification {
  min-height: 170px;
  background-color: #901729;
  position: relative;
  padding-top: 42px;
  padding-right: 40px;
  padding-bottom: 44px;
  padding-left: 44px;
  margin-bottom: 4px;
  @media screen and (max-width: $mobile) {
    padding-top: 36px;
    padding-right: 22px;
    padding-bottom: 40px;
    padding-left: 22px;
  }
  .close-button {
    position: absolute;
    display: flex;
    right: 14px;
    top: 14px;
    @include cursorPointer;
  }
  .notification-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 14px;
    @media screen and (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }
  .notification-body {
    display: grid;
    grid-template-columns: 1fr max-content;

    font-size: 16px;
    line-height: 21px;
    @media screen and (max-width: $mobile) {
      font-size: 14px;
      line-height: 18px;
    }
    button {
      padding: 0 40px;
      @media screen and (max-width: $mobile) {
        padding: 0 20px;
      }
    }
  }
}

.plus-banner {
  height: 179px;
  margin-bottom: 4px;
  background: linear-gradient(-58deg, rgba(18, 18, 18, 0) 49%, #121212 49%)
      no-repeat,
    url("../../../assets/spartan_plus_bg_small.jpg") no-repeat 220% 50%/87%;
  position: relative;

  .banner-content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 44px;
    @media screen and (max-width: 499px) {
      padding-left: 22px;
    }
    .information-side {
      padding: 34px 0 0 0;
      .banner-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 23px;
        .spartan-logo {
          height: 27px;
          width: 27px;
        }
        .spartan-text-logo {
          height: 26px;
          width: 106px;

          margin: 0 4px 0 2px;
        }
        .plus-icon {
          position: relative;
          .plus-line {
            height: 4px;
            width: 18px;
            background: #ffffff;
          }
          .rotate-line {
            position: absolute;
            top: 0;
            transform: rotate(90deg);
          }
        }
      }
      .banner-description {
        padding-left: 4px;
        .description-line {
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;

          font-size: 14px;
          line-height: 14px;
          font-weight: 500;

          text-transform: capitalize;
          i {
            margin-right: 6px;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
    .banner-buttons {
      padding: 14px 0 29px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .close-button {
        text-align: end;
        i {
          // color: #000000;
          font-size: 28px;
        }
      }
      .mem-btn {
        padding: 0 32px;
        margin-right: 24px;
      }
      .btn-primary:hover {
        opacity: 1;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .plus-banner {
    background: #121212;
    height: 195px;
    .banner-content {
      .information-side {
        padding-top: 43px;
        .banner-title {
          margin-bottom: 26px;
        }
        .banner-description {
          padding-left: 0;
          .description-line {
            font-weight: 600;
          }
        }
      }
      .banner-buttons {
        padding-bottom: 41px;
        .close-button {
          i {
            color: #ffffff;
          }
        }
        .mem-btn {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>